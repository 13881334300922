.login {
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    background: url(../../assets/images/login__bgi.png) no-repeat center;
    background-size: cover;

    .main {
        width: 800px;
        height: 460px;
        border-radius: 8px;

        .banner {
            flex: 1;
            height: 100%;
            background: url(../../assets/images/login__banner.png) no-repeat center;
            background-size: cover;
        }

        .panel {
            width: 395px;
            height: 100%;
            padding: 25px 15px 0;
            box-sizing: border-box;
            background-color: #fff;

            .header {
                align-items: center;
                margin-bottom: 38px;

                .logo {
                    width: 20px;
                    margin-right: 10px;
                }

                .title {
                    font-size: 22px;
                    color: #000;
                }
            }

            .form-icon {
                color: #1890ff;
            }

            .login-btn {
                width: 100%;
                margin-top: 46px;
            }
        }
    }
}
