@import './assets/icons/index.module.less';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    width: 100%;
    height: 100%;
}

// 全局通用样式
.flex {
    display: flex !important;
}

.grid {
    display: grid !important;

    &_inline {
        display: inline-grid !important;
    }
}

.clear-fix::before,
.clear-fix::after {
    content: '';
    display: table;
}

.hide {
    display: none;
    opacity: 0;
    visibility: hidden;
}

.filter {
    &__input {
        min-width: 200px;
    }

    &__select {
        min-width: 120px;
    }
}

.full-width {
    width: 100%;
}

.form-item {
    &__gap {
        margin-top: 10px;
    }
}

.link-button {
    height: auto !important;
}

.placeholder-b:not(.ant-select-disabled) {
    .ant-select-selection-placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
    }
}

.line-through {
    text-decoration: line-through;
}

.wx-tag {
    color: #34b200;

    &_error {
        color: #fe4545;
    }
}

.nickname {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

/* antd 重置样式 */
// 列表单元格空值
.ant-table-tbody .ant-table-cell:empty::after {
    content: '-';
}

.ant-btn-link {
    padding: 0;
    height: auto;
    line-height: inherit;
    border: none;
}
