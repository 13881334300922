.login {
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    background: url(../../assets/images/login__bgi.png) no-repeat center;
    background-size: cover;

    .main {
        width: 800px;
        height: 460px;
        border-radius: 8px;

        .banner {
            flex: 1;
            height: 100%;
            background: url(../../assets/images/login__banner.png) no-repeat center;
            background-size: cover;
        }

        .panel {
            width: 395px;
            height: 100%;
            padding: 25px 15px 0;
            box-sizing: border-box;
            background-color: #fff;

            .header {
                align-items: center;
                margin-bottom: 38px;

                .logo {
                    width: 20px;
                    margin-right: 10px;
                }

                .title {
                    font-size: 22px;
                    color: #000;
                }
            }

            .qrcode-wrap {
                flex-direction: column;
                align-items: center;
                width: 129px;
                height: 129px;
                margin: 30px auto 0;
                border: 1px solid #e7e7e7;
                box-sizing: border-box;
                cursor: pointer;

                .icon {
                    width: 60px;
                    height: auto;
                    margin: 18px 0 11px;
                }

                .label {
                    font-size: 14px;
                    color: #000;
                }
            }

            .form-icon {
                color: #1890ff;
            }

            .login-btn {
                width: 100%;
                margin-top: 46px;
            }
        }
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100vw;

        .filing-number {
            font-size: 13px;
            color: #999;
        }
    }
}
