@font-face {
    font-family: 'cm__icon';
    src: url('fonts/cm__icon.eot?nk3ya7');
    src: url('fonts/cm__icon.eot?nk3ya7#iefix') format('embedded-opentype'),
        url('fonts/cm__icon.ttf?nk3ya7') format('truetype'), url('fonts/cm__icon.woff?nk3ya7') format('woff'),
        url('fonts/cm__icon.svg?nk3ya7#cm__icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='cm__icon_'],
[class*=' cm__icon_'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cm__icon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cm__icon_user:before {
    content: '\e923';
}
.cm__icon_sheet-disabled:before {
    content: '\e920';
}
.cm__icon_debt:before {
    content: '\e921';
}
.cm__icon_unsafe:before {
    content: '\e922';
}
.cm__icon_point-up:before {
    content: '\ea03';
}
.cm__icon_wechat:before {
    content: '\e90e';
}
.cm__icon_bg-colors:before {
    content: '\e91b';
}
.cm__icon_bold:before {
    content: '\e91c';
}
.cm__icon_font-colors:before {
    content: '\e91d';
}
.cm__icon_align-justify:before {
    content: '\e91e';
}
.cm__icon_picture:before {
    content: '\e91f';
}
.cm__icon_align-center:before {
    content: '\e907';
}
.cm__icon_align-left:before {
    content: '\e90c';
}
.cm__icon_align-right:before {
    content: '\e90d';
}
.cm__icon_brush:before {
    content: '\e90f';
}
.cm__icon_eraser:before {
    content: '\e910';
}
.cm__icon_italic:before {
    content: '\e911';
}
.cm__icon_strikethrough:before {
    content: '\e912';
}
.cm__icon_underline:before {
    content: '\e913';
}
.cm__icon_turn-right:before {
    content: '\e914';
}
.cm__icon_turn-left:before {
    content: '\e915';
}
.cm__icon_bars:before {
    content: '\e916';
}
.cm__icon_line-height:before {
    content: '\e917';
}
.cm__icon_letter-space:before {
    content: '\e918';
}
.cm__icon_smile:before {
    content: '\e919';
}
.cm__icon_store:before {
    content: '\e900';
}
.cm__icon_peoples:before {
    content: '\e901';
}
.cm__icon_rect:before {
    content: '\e902';
}
.cm__icon_group:before {
    content: '\e903';
}
.cm__icon_diamond:before {
    content: '\e904';
}
.cm__icon_factory:before {
    content: '\e905';
}
.cm__icon_gift:before {
    content: '\e906';
}
.cm__icon_share:before {
    content: '\e91a';
}
.cm__icon_star:before {
    content: '\e908';
}
.cm__icon_sound:before {
    content: '\e909';
}
.cm__icon_delete:before {
    content: '\e90a';
}
.cm__icon_stethoscope:before {
    content: '\e90b';
}
