.main {
    width: 100vw;
    height: 100vh;
}

.header {
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 15px !important;
    background-color: #2b58ad !important;
    color: #fff !important;

    .left {
        font-size: 20px;
    }

    .right {
        align-items: center;

        .dropdown {
            font-size: 13px;
            cursor: pointer;

            .icon {
                margin-left: 6px;
            }
        }

        .dividing-line {
            display: block;
            width: 1px;
            height: 20px;
            background: rgba(255, 255, 255, 0.16);
            margin: 0 12px 0 20px;
        }

        .avatar {
            width: 34px;
        }

        .name {
            font-size: 14px;
            margin: 0 20px 0 14px;
        }

        .logout-btn {
            font-size: 15px;
            cursor: pointer;
        }
    }
}

.sider {
    @toolbarHeight: 28px;

    background-color: #f6f6f6 !important;
    box-shadow: 1px 0px 0px 0px #ebebeb;

    .toolbar {
        justify-content: flex-end;
        align-items: center;
        height: @toolbarHeight;
        padding-right: 10px;
    }

    .menu {
        height: calc(100% - @toolbarHeight);
        overflow: auto;
        background-color: #fff;
    }
}

.content {
    @tabHeight: 28px;

    .tabs {
        height: @tabHeight;
        box-sizing: border-box;
        background-color: #f6f6f6;
        border-bottom: 1px solid #e6eaee;

        .tab {
            position: relative;
            line-height: @tabHeight;
            padding: 0 15px;
            font-size: 12px;
            background-color: #fff;
            border-right: 1px solid #e6eaee;
            color: #354052;
            cursor: pointer;

            .icon {
                font-size: 13px;
                margin-left: 8px;
                color: #898989;

                &:hover {
                    color: #0f71e2;
                }
            }

            &_active {
                color: #0f71e2;
                background-color: #d8e9fd;
            }
        }
    }

    .wrap {
        height: calc(100% - @tabHeight);
        overflow: auto;
        display: none;
        background-color: #f7f8fa;

        &_active {
            display: block;
        }
    }
}
